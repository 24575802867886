/*------------------------------------*\
    _save-the-date.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Events - Save the date
\*------------------------------------*/

.events-information {
  background-color: $background-color-save-the-date-content;
  display: none;
  margin-top: 40px;
  padding: 40px;

  @include respond-to(tablet) {
    display: block;

    h3 {
      margin-bottom: 15px;
      text-align: center;
      font-family: $font-family-arepey;
      font-weight: unset;
      font-size: 22pt;
    }
  }

  p {
    font-family: $font-family-pontano-sans;
    font-weight: unset;
    font-size: 12pt;
    line-height: 1.2;
  }
}

.Information-wrapper {
  background-color: $background-color-white;
  margin-top: 100px;
  padding: 165px 40px 20px;
  position: relative;
  width: 200px;

  @include respond-to(tablet) {
    margin-top: 50px;
    padding: 150px 30px 210px;

    ul {
      border-bottom: 2px solid black;
      width: 170px;
    }

    @include respond-to(screen) {
      padding: 150px 30px 100px;
    }

    @include respond-to(medium-screen) {
      padding: 150px 30px 50px;
    }
  }

  img {
    height: auto;
    left: 0;
    max-width: 100%;
    position: absolute;
    top: -135px;
  }

  li {
    color: black;
    list-style: square;
    padding: 25px 15px 25px 0;
    font-family: $font-family-pontano-sans;
    font-weight: unset;
    font-size: 12pt;
  }

  .paragraph-pop-up {

    h3 {
      text-align: center;
      margin: 10px;
    }
  }
}

.save-the-date {
  font-family: $font-family-arepey;
  font-weight: unset;
  font-size: 45pt;

  @include respond-to(tablet) {
    margin: 80px 0 -40px 0;
  }
}

.button-ticket {
  text-align: center;

  .ticket {
    background-color: $button-background-color;
    border: none;
    color: $background-color-white;
    cursor: pointer;
    font-family: $font-family-pontano-sans;
    font-size: 12pt;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px 15px;

    &:hover {
      background-color: greenyellow;
      color: darkslategray;
    }

    &:focus {
      outline: 0;
    }

    a {
      text-decoration: none;
    }

    .ticket-mobile {
      display: block;

      @include respond-to(tablet) {
        display: none;
      }
    }

    .ticket-desktop {
      display: none;

      @include respond-to(tablet) {
        display: block;
      }
    }
  }
}

#section-wrapper-fourth {

  @include respond-to(tablet) {
    flex-direction: unset;
    justify-content: unset;
  }

  @include respond-to(large-screen) {
    padding: 0 400px;
  }
}