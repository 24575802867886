/*------------------------------------*\
    _privacy.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Impressum
    :: Datenschutz
    :: Disclaimer
\*------------------------------------*/

.section-privacy {
  margin: 50px;
  color: $background-color-white;

  img {
    @include respond-to(tablet) {
      float: right;
    }
  }

  h1, h2, h3 {
    margin: 15px 0;
  }

  p {
    font-size: 12pt;
    line-height: 1.6;
  }

  a {
    color: $background-color-white;
  }

  button {
    background-color: $background-color;
    border: none;
    margin: 15px 0;
    font-size: 12pt;
  }
}