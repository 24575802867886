/*------------------------------------*\
    _navigation.scss
\*------------------------------------*/

/*------------------------------------*\
    :: MAIN MENU
\*------------------------------------*/
.header {
  .nav-bar {
    align-items: center;
    background-color: $background-color-nav-bar;
    display: flex;
    height: 50px;
    margin: 30px 0;
    position: fixed;
    width: 100%;
    justify-content: center;
    z-index: 3;

    @include respond-to(tablet) {
      margin: 80px 0;
    }

    img {
      height: 180%;
      position: absolute;

      @include respond-to(tablet) {
        height: 200px;
      }
    }

    .nav-bar-background-filler {
      background-color: $background-color;
      height: 30px;
      position: fixed;
      top: 0;
      width: 100%;

      @include respond-to(tablet) {
        height: 80px;
      }
    }

    a {
      font-family: $font-family-arepey;
      font-size: 22pt;
      color: $background-color-fonts;
      display: none;

      &:visited {
        color: $background-color-fonts;
      }

      &:hover {
        color: $background-color-section;
      }
    }

    @include respond-to(tablet) {

      .link-wrapper {
        flex-grow: 1;
      }

      a {
        display: inline-block;
      }

      .link-one {
        margin-left: 40px;

        @include respond-to(screen) {
          margin-left: 50px;
        }

        @include respond-to(medium-screen) {
          margin-left: 80px;
        }
      }

      #link-third {
        flex-grow: 25;

        @include respond-to(screen) {
          flex-grow: 4;
        }

        @include respond-to(medium-screen) {
          flex-grow: 2;
        }
      }
    }
  }

  .news-letter {
    height: 40px;
    opacity: 0.6;
    position: fixed;
    right: 115px;
    top: 100px;
    z-index: 3;

    @include respond-to(tablet) {
      right: 240px;
      top: 200px;
    }

    img {
      height: 180%;
      image-rendering: auto;
      position: absolute;

      @include respond-to(tablet) {
        height: 150px;
      }
    }
  }

  /* Position and sizing of burger button */

  .bm-burger-button {
    height: 30px;
    left: 36px;
    margin: 30px 0;
    position: fixed;
    top: 10px;
    width: 36px;

    @include respond-to(tablet) {
      display: none;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $background-color-white;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: $background-color-white;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $background-color-white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    height: 100%;
    position: fixed;
  }

  /* General sidebar styles */
  .bm-menu {
    background: $background-color;
    font-size: 1.15em;
    padding: 2.5em 1.5em 0;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: $background-color-white;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: $background-color;
  }

  .right .bm-burger-button {
    left: initial;
    padding: 30px;
    right: 36px;
  }

  .bm-morph-shape {
    fill: $background-color;
  }

  .bm-menu {
    background: $background-color;

    a {
      color: $background-color-fonts;

      &:hover,
      &:focus {
        color: $background-color-fonts;
        cursor: pointer;
      }
    }
  }

  .bm-item-list a {
    padding: 0.8em;

    span {
      font-weight: 700;
      margin-left: 10px;
    }
  }

  .bm-item:focus {
    outline: none;
  }

  a {
    color: $background-color-fonts;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $background-color-section;
      cursor: pointer;
    }
  }

}
