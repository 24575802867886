/*------------------------------------*\
    _partner.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Partner
\*------------------------------------*/

.partner {

  h1 {
    color: $background-color-white;
    text-align: center;
    font-family: $font-family-pontano-sans;
    font-size: 45pt;
    font-weight: unset;
    font-style: unset;
  }

  .partner-content {
    margin:  50px;

    @include respond-to(tablet) {
      display: flex;
      margin: 150px;
    }

    span {
      color: $background-color-white;
      margin: 5px;
      font-family: $font-family-pontano-sans;
      font-weight: unset;
      font-style: unset;
      font-size: 12pt;
    }

    .partner-adress {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      flex-grow: 1;
      text-align: center;

      @include respond-to(tablet) {
        margin: 0;
      }
    }
  }
}

a {
  color: $background-color-white;
}

.partner-button {
  background-color: $background-color;
  border: none;
  font-size: 12pt;
  margin: 15px;
}