/*------------------------------------*\
    _popup-Modal.scss
\*------------------------------------*/


/*------------------------------------*\
    :: PopUp Modal
\*------------------------------------*/

.pop-up-modal {
  display: flex;
  justify-content: center;

  @include respond-to(tablet) {
    display: none;
  }

  .paragraph-pop-up {
    background-color: $background-color-section;
    margin: 0;
    padding: 25px;
  }

  span {
    top: 6px;
    right: 14px;

    .bm-cross-first{
      background-color: $background-color-white;
      position: absolute;
      width: 3px;
      height: 14px;
      transform: rotate(45deg);
    }

    .bm-cross-second {
      background-color: $background-color-white;
      position: absolute;
      width: 3px;
      height: 14px;
      transform: rotate(-45deg);
    }
  }

  input {
    border-radius: 10px;
    background-color: $background-color-nav-bar;
    border: none;
    color: $background-color-white;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 16px;

    &:focus {
      outline: 0;
    }
  }
}

.show-more {
  padding-top: 25px;
}