/*------------------------------------*\
    _mixins.scss
\*------------------------------------*/


///*------------------------------------*\
//    @mixin border-radius
//\*------------------------------------*/
//@mixin border-radius($radius) {
//  -webkit-border-radius: $radius;
//     -moz-border-radius: $radius;
//      -ms-border-radius: $radius;
//          border-radius: $radius;
//}
//
//
///*------------------------------------*\
//    @mixin transition
//\*------------------------------------*/
//@mixin transition($value) {
//  -webkit-transition: $value;
//     -moz-transition: $value;
//      -ms-transition: $value;
//          transition: $value;
//}
//
//
///*------------------------------------*\
//    @mixin transform
//\*------------------------------------*/
//@mixin transform($value) {
//  -webkit-transform: $value;
//     -moz-transform: $value;
//      -ms-transform: $value;
//          transform: $value;
//}
//
//
///*------------------------------------*\
//    @mixin transform-origin
//\*------------------------------------*/
//@mixin transform-origin($value) {
//  -webkit-transform-origin: $value;
//     -moz-transform-origin: $value;
//      -ms-transform-origin: $value;
//          transform-origin: $value;
//}
//
//
///*------------------------------------*\
//    @mixin box-shadow
//\*------------------------------------*/
//@mixin box-shadow($shadow) {
//  -webkit-box-shadow: $shadow;
//     -moz-box-shadow: $shadow;
//      -ms-box-shadow: $shadow;
//          box-shadow: $shadow;
//}
//
//
///*------------------------------------*\
//    @mixin flex-wrap
//\*------------------------------------*/
//@mixin flex-wrap($value) {
//  -webkit-flex-wrap: $value;
//     -moz-flex-wrap: $value;
//      -ms-flex-wrap: $value;
//          flex-wrap: $value;
//}
//
//
///*------------------------------------*\
//    @mixin flex-direction
//\*------------------------------------*/
//@mixin flex-direction($value) {
//  -webkit-flex-direction: $value;
//     -moz-flex-direction: $value;
//      -ms-flex-direction: $value;
//          flex-direction: $value;
//}
//
//
///*------------------------------------*\
//    @mixin flex-basis
//\*------------------------------------*/
//@mixin flex-basis($value) {
//  -webkit-flex-basis: $value;
//     -moz-flex-basis: $value;
//      -ms-flex-basis: $value;
//          flex-basis: $value;
//}
//
//
///*------------------------------------*\
//    @mixin flex-grow
//\*------------------------------------*/
//@mixin flex-grow($value) {
//  -webkit-flex-grow: $value;
//     -moz-flex-grow: $value;
//      -ms-flex-grow: $value;
//          flex-grow: $value;
//}
//
//
///*------------------------------------*\
//    @mixin justify-content
//\*------------------------------------*/
//@mixin justify-content($value) {
//  -webkit-justify-content: $value;
//     -moz-justify-content: $value;
//      -ms-justify-content: $value;
//          justify-content: $value;
//}
//
//
///*------------------------------------*\
//    @mixin align-items
//\*------------------------------------*/
//@mixin align-items($value) {
//  -webkit-align-items: $value;
//     -moz-align-items: $value;
//      -ms-align-items: $value;
//          align-items: $value;
//}
//
//
///*------------------------------------*\
//    @mixin clearfix
//\*------------------------------------*/
//@mixin clearfix {
//  &:after {
//    content: "";
//    display: table;
//    clear: both;
//  }
//}


/*------------------------------------*\
    @mixin respond-to
\*------------------------------------*/
@mixin respond-to($media) {
  @if $media == mobile-landscape {
    @media only screen and (min-width: $break-mobile-l) { @content; }
  }
  @else if $media == tablet {
    @media only screen and (min-width: $break-tablet) { @content; }
  }
  @else if $media == screen {
    @media only screen and (min-width: $break-screen) { @content; }
  }
  @else if $media == medium-screen {
    @media only screen and (min-width: $break-screen-m) { @content; }
  }
  @else if $media == large-screen {
    @media only screen and (min-width: $break-screen-l) { @content; }
  }
}

//
///*------------------------------------*\
//    @function calc-em
//\*------------------------------------*/
//@function calc-em($target-px, $context) {
//  @return ($target-px / $context) * 1em;
//}