/*------------------------------------*\
    _team.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Team
\*------------------------------------*/

.meinTeam-paragraph {
  display: none;
  margin-top: 15px;
  text-align: center;
  font-family: $font-family-pontano-sans;
  font-size: 12pt;
  font-weight: unset;

  @include respond-to(tablet) {
    display: block;
  }
}