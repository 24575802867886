/*------------------------------------*\
    _about-me.scss
\*------------------------------------*/

/*------------------------------------*\
    :: About Me
\*------------------------------------*/
#section-about-me {
  @include respond-to(tablet) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: normal;
  }

  @include respond-to(screen) {
    align-items: flex-end;
    flex-direction: unset;

  }

  .about-me {

    @include respond-to(tablet) {
      padding: 20px 0 0 30px;
    }

    @include respond-to(large-screen) {
      padding: 20px 175px 0 30px;
    }

    h2 {
      font-family: $font-family-arepey;
      font-style: italic;
      font-size: 23pt;
      line-height: 1.3;
      text-align: center;
    }

    p {
      display: none;
      font-family: $font-family-pontano-sans;
      font-size: 15pt;
      line-height: 1.2;
    }

    @include respond-to(screen) {
      p {
        display: block;
      }
    }
  }

  .pop-up-modal {
    display: block;

    @include respond-to(screen) {
      display: none;
    }
  }
}