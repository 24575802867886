/*------------------------------------*\
    _event.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Gallery / Video / Programm
\*------------------------------------*/

.divBackground {
  background-color: #A5A5A5;
}

.swiper-container-cube {
  height: auto;
}

///* Safari 10.1+ (alternate method) */
//
//@media not all and (min-resolution:.001dpcm)
//{ @supports (-webkit-appearance:none) {
//
//  .swiper-wrapper {
//      transform-origin: unset !important;
//      transform: unset !important;
//  }
//}}

/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .swiper-container-cube {

    @include respond-to(tablet) {
      //height: 400px; //todo hier raus nehmen evtl
    }
  }
}}

.swiper-slide {
  transition-duration: 2s;
  background-color: #A5A5A5;
}

  ///* Safari 10.1+ (alternate method) */
  //
  //@media not all and (min-resolution:.001dpcm)
  //{ @supports (-webkit-appearance:none) {
  //
  //  .swiper-button-prev {
  //
  //    @include respond-to(tablet) {
  //      left: -30px !important;
  //    }
  //  }
  //}}

  ///* Safari 10.1+ (alternate method) */
  //
  //@media not all and (min-resolution:.001dpcm)
  //{ @supports (-webkit-appearance:none) {
  //
  //  .swiper-button-next {
  //
  //    @include respond-to(tablet) {
  //      right: -30px !important;
  //    }
  //  }
  //}}

.border-wrapper-top {
  display: none;

  p {
    font-family: $font-family-pontano-sans;
    font-weight: bold;
    font-size: 22pt;
  }

  /* Safari 10.1+ (alternate method) */

  @media not all and (min-resolution:.001dpcm)
  {
    @supports (-webkit-appearance:none) {

    p {
      width: 600px;
      font-size: 22pt;

      @include respond-to(tablet) {
        width: 400px;
        font-size: 14pt;
      }

      @include respond-to(medium-screen) {
        width: 600px;
        font-size: 22pt;
      }
    }
  }}

  @media not all and (min-resolution:.001dpcm)
  {
    @supports (-webkit-appearance:none) {
      .top-harmony {
          width: 390px;
      }

      .top-balance {
        width: 500px;
      }

      .top-business {
        width: 550px;
      }

      .harmony-img {
        height: 125px;
      }
    }}

  img {
    height: 110px;
    margin-left:10px;
  }

  ///* Safari 10.1+ (alternate method) */
  //
  //@media not all and (min-resolution:.001dpcm)
  //{ @supports (-webkit-appearance:none) {
  //
  //  img {
  //    height: 110px;
  //    margin-left:10px;
  //
  //    @include respond-to(tablet) {
  //      height: 70px;
  //      margin-left: -20px;
  //    }
  //
  //    @include respond-to(medium-screen) {
  //      height: 110px;
  //      margin-left:10px;
  //    }
  //  }
  //}}

  @include respond-to(tablet) {
    align-items: center;
    background-color: $background-color-section-event;
    display: flex;
    height: 20px;
    margin-bottom: -13px;
    padding: 0 10px;
    z-index: 1;

  }
}

#business {
  border-color: yellow;
}

///* Safari 10.1+ (alternate method) */
//
//@media not all and (min-resolution:.001dpcm)
//{ @supports (-webkit-appearance:none) {
//
//  .border-wrapper {
//    @include respond-to(tablet) {
//      padding: 40px 0 !important;
//    }
//  }
//}}

.border-harmony {
  border: 5px solid $border-color-harmony !important;
}

.border-wrapper {
  align-items: center;
  border: 5px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;

  @include respond-to(tablet) {
    flex-direction: unset;
    justify-content: space-around;
    position: relative;

    .hash-mobile {
      display: none;
    }
  }

  .box {
    margin: 20px;
    //position:relative;
    @include respond-to(tablet) {
      width: 100%;
    }

    @include respond-to(medium-screen) {
      margin: 20px 50px;
    }

    //.placeholder-image {
    //  position: absolute;
    //  z-index: 1;
    //  &.hidden {
    //    display:none;
    //  }
    //}
    video {
      cursor: pointer;
      width: 100%;
      background-color: $background-color-section-event;

      @include respond-to(tablet) {
        background-color: $background-color-section;
      }
    }

    img {
      cursor: pointer;
    }
  }

  .box-second {
    margin: 20px;
    //position:relative;
    @include respond-to(tablet) {
      width: 20%;
    }

    @include respond-to(medium-screen) {
      margin: -40px 270px;
    }

    //.placeholder-image {
    //  position: absolute;
    //  z-index: 1;
    //  &.hidden {
    //    display:none;
    //  }
    //}
    video {
      cursor: pointer;
      width: 100%;
      background-color: $background-color-section-event;

      @include respond-to(tablet) {
        background-color: $background-color-section;
      }
    }

    img {
      cursor: pointer;
    }
  }

  #box-second-Business {

    @include respond-to(tablet) {
      width: 23%;
      margin-left: -200px;
    }
  }

  .border-span {
    background-color: $background-color-section-event;
    display: none;
    height: 10px;
    position: absolute;
    width: 10px;

    @include respond-to(tablet) {
      display: block;
    }
  }
}



.border-wrapper-bottom {
  display: none;

  @include respond-to(tablet) {
    align-items: center;
    background-color: $background-color-section-event;
    display: flex;
    height: 20px;
    margin-top: -13px;
    padding: 0 10px;
    z-index: 1;

  }
}

#section-three {
  @include respond-to(tablet) {
    background-color: $background-color-section-event;
  }
}

#section-wrapper-three {
  @include respond-to(medium-screen) {
    padding: 0;
  }

  h2 {
    color: $background-color-white;
    font-family: $font-family-pontano-sans;
    font-weight: unset;
    font-size: 25pt;

    @include respond-to(tablet) {
      color: $background-color-black;
      font-size: 30pt;
    }
  }

  p {
    color: $background-color-black;
  }
}

@include respond-to(tablet) {
  #top-left {
    left: -6px;
    top: 0;
  }

  #top-right {
    right: -6px;
    top: 0;
  }

  #bottom-left {
    bottom: -6px;
    left: 0;
  }

  #bottom-right {
    bottom: -6px;
    right: 0;
  }
}

@include respond-to(screen) {
  #top-left {
    left: -6px;
    top: 0;
  }

  #top-right {
    right: -6px;
    top: 0;
  }

  #bottom-left {
    bottom: -6px;
    left: 0;
  }

  #bottom-right {
    bottom: -6px;
    right: 0;
  }
}