/*------------------------------------*\
    _speaker.scss
\*------------------------------------*/

.section-wrapper {
  display: flex;
  justify-content: space-around;
}

.speakercontent {
  margin: 10px;
  width: 300px;
  height: 200px;
}