/*------------------------------------*\
    _sections.scss
\*------------------------------------*/
.section {
  background-color: $background-color-section;
  padding: 100px 35px;

  &:first-child {
    padding-top: 135px;

    @include respond-to(tablet) {
      padding-top: 300px;
    }
  }

  h2 {
    margin: 25px 0;
    text-align: center;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .section-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(screen) {
      padding: 0 70px;
    }

    @include respond-to(medium-screen) {
      padding: 0 170px;
    }

    h2 {
      font-family: $font-family-arepey;
      font-size: 40pt;
      font-weight: unset;
      margin: 10px 0;
    }

    .eventteam {

      @include respond-to(screen) {
        height: 630px;
      }
    }

    .businessfoto {

      @include respond-to(mobile-landscape) {
        height: 300px;
      }

      @include respond-to(tablet) {
        height: 450px;
      }
    }

    button {
      text-align: center;
    }
  }
}

.section-seperation {
  padding: 40px 35px;
}

.safari {
  display: none;
}

/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .safari {
      display: block;
  }

  .chrome {
    display: none;
  }
}}