/*------------------------------------*\
    _footer.scss
\*------------------------------------*/

/*------------------------------------*\
    :: Footer
\*------------------------------------*/

.footer {
  div {
    margin: 30px 0;

    p {
      margin: 5px 0;
      font-family: $font-family-pontano-sans;
      font-weight: unset;
      font-size: 10pt;
    }
  }
}

.section-footer {
  background-color: $background-color;
  margin-top: 50px;
  padding: 15px;

  .section-wrapper-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer-mobile {
      display: block;

      @include respond-to(tablet) {
        display: none;
      }

      .social-media {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;

        img {
          height: auto;
          width: 50px;
        }
      }

      .footer-content {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        align-items: center;

        a {
          color: $background-color-fonts;
          margin: 10px 0;
          text-decoration: none;
          font-family: $font-family-pontano-sans;
          font-weight: unset;
          font-size: 10pt;
        }

        span {
          margin: 5px 0;
          font-family: $font-family-pontano-sans;
          font-weight: unset;
          font-size: 10pt;

          &:first-child {
            margin: 0;
          }
        }
      }
    }

    .footer-desktop {
      display: none;

      @include respond-to(tablet) {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
      }

      @include respond-to(screen) {
        padding: 15px 100px;
      }

      @include respond-to(medium-screen) {
        align-self: center;
        width: 1000px;
      }

      .div-content {
        float: left;
        height: 152px;
        margin: 0 15px;
        width: 152px;

        img {
          width: 100%;
        }
      }

      .social-media {
        float: right;
        margin-top: 15px;

        img {
          height: auto;
          width: 50px;
        }
      }

      .footer-content {
        display: flex;
        flex-direction: column;

        a {
          color: $background-color-fonts;
          margin: 10px 0;
          text-decoration: none;
          font-family: $font-family-pontano-sans;
          font-weight: unset;
          font-size: 12pt;

          &:first-child {
            margin: 0;
          }
        }

        span {
          margin: 5px 0;
          font-family: $font-family-pontano-sans;
          font-weight: unset;
          font-size: 12pt;

          &:first-child {
            margin: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .contact {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
        }
      }

      #footer-adress {
        flex-grow: 1;
        margin-top: 5px;
        margin-left: 20px;
      }
    }
  }
}