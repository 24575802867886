/*------------------------------------*\
    _config.scss
\*------------------------------------*/


/*------------------------------------*\
    :: COLORS
\*------------------------------------*/

/*** primary colors ***/
$background-color:  #424242;
$background-color-section: #59595A;
$background-color-nav-bar: #B2B2B2;

/*** secondary colors ***/
$background-color-white: #FFFFFF;
$background-color-black: #3D3D3D;

/*** efficiency colors ***/


/*** special colors ***/
$background-color-section-event: #A5A5A5;
$background-color-save-the-date-content: #727272;
$background-color-fonts: #FFFFFF;
$border-color: #AFDE67;
$border-color-harmony: #991815;
$button-background-color: #963400;

/*------------------------------------*\
    :: FONTS
\*------------------------------------*/
$font-family-arepey: 'Arapey', serif;
$font-family-pontano-sans: 'Pontano Sans', sans-serif;




/*------------------------------------*\
    :: BREAK POINTS
\*------------------------------------*/
$break-mobile-l: 568px;
$break-tablet: 768px;
$break-screen: 1024px;
$break-screen-m: 1281px;
$break-screen-l: 1486px;